<template>
<div>
    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-add`)|| $store.state.user.userType.id == $global.state.roleType.admin">
            <v-btn @click="openAddDialog()" color="primary" large class=" btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchUser" label="الإسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
       

    </v-row>
    <v-card id="card">
        <v-data-table class="mytable" :items-per-page="$global.state.filter.pageSize" :items="items" :loading="loading" :headers="headers" hide-default-footer loading-text="جاري تحميل البيانات" no-data-text="لا توجد بيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.phoneNumber="{item}">
                <div dir="ltr">{{item.phoneNumber}}</div>
            </template>

            <template v-slot:item.deliveryCost="{item}">
                <div>{{$service.formatCurrency(item.deliveryCost)}}</div>
            </template>

            <template v-slot:item.photoUrl="{item}">
                <v-avatar rounded>
                    <v-img :src="item.photoUrl == null ? require('@/assets/images/photo.svg') : $url + item.photoUrl"/>
                </v-avatar>
            </template>

            <template v-slot:item.isActive="{item}">
                <v-chip v-if="item.isActive == true" color="teal" dark>مفعل</v-chip>
                <v-chip v-if="item.isActive == false" color="error" dark>غير مفعل</v-chip>
            </template>

            <template v-slot:item.userAreas="{item}">
                <div v-if="item.userAreas.length > 0">
                    <v-chip  v-for="(a, i) in item.userAreas" :key="i"><div v-if="a.area != null">{{ a.area.name }}</div></v-chip>
                </div>
            </template>

            <template v-slot:item.actions="{item, index}">

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`) || $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`) || $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-changePassword`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openChangePasswordDialog(item)" v-on="on" icon>
                            <v-icon color="indigo">
                                fi fi-rr-key
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تغيير كلمة المرور</span>
                </v-tooltip>
                <v-tooltip bottom="" v-if="$route.path == '/delivery_delegater' || $route.path == '/receiving_delegater'">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="assignAreas(item)" v-on="on" icon>
                            <v-icon color="purple">
                                fi fi-rr-route
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تحديد مناطق المندوب</span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
    <ChangePasswordDialog />
    <AssignAreasDialog />
</div>
</template>

<script>
export default {
    props: ['headers'],
    components: {
        Dialog: () => import("./dialog"),
        ChangePasswordDialog: () => import("./changePasswordDialog"),
        AssignAreasDialog: () => import("./assignAreas"),
    },
    data() {
        return {
            loading: false,
            items: [],
            searchUser: '',
            search: ''
        }
    },

    created() {
        this.getItems()

        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);
    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async getItems() {
            this.loading = true;
            try {
                const res = await this.$http.get(`User?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&UserTypeId=${this.$route.meta.type}&FullName=${this.searchUser}`);
                this.items = res.data.result;
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.loading = false;
            }
        },

        async deleteItem(id, i) {
            await this.$service.deleteItem(id, `User`, `delete`, 'هل أنت متأكد؟')
            this.$eventBus.$once(`delete`, () => {
                this.getItems()
            })
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },

        assignAreas(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog3')
            this.$eventBus.$emit('fill-fields')
        },

        openChangePasswordDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog2')
            this.$eventBus.$emit('fill-fields')
        },
    },
}
</script>
