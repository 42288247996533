var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:" mb-3",attrs:{"align":"center"}},[(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-add"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:" btn",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إضافة "+_vm._s(this.$route.meta.single))])],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],staticClass:"mr-1",attrs:{"label":"الإسم","hide-details":"","prepend-inner-icon":"search","dense":"","outlined":"","filled":""},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1)],1),_c('v-card',{attrs:{"id":"card"}},[_c('v-data-table',{staticClass:"mytable",attrs:{"items-per-page":_vm.$global.state.filter.pageSize,"items":_vm.items,"loading":_vm.loading,"headers":_vm.headers,"hide-default-footer":"","loading-text":"جاري تحميل البيانات","no-data-text":"لا توجد بيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(item.phoneNumber))])]}},{key:"item.deliveryCost",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$service.formatCurrency(item.deliveryCost)))])]}},{key:"item.photoUrl",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"rounded":""}},[_c('v-img',{attrs:{"src":item.photoUrl == null ? require('@/assets/images/photo.svg') : _vm.$url + item.photoUrl}})],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [(item.isActive == true)?_c('v-chip',{attrs:{"color":"teal","dark":""}},[_vm._v("مفعل")]):_vm._e(),(item.isActive == false)?_c('v-chip',{attrs:{"color":"error","dark":""}},[_vm._v("غير مفعل")]):_vm._e()]}},{key:"item.userAreas",fn:function(ref){
var item = ref.item;
return [(item.userAreas.length > 0)?_c('div',_vm._l((item.userAreas),function(a,i){return _c('v-chip',{key:i},[(a.area != null)?_c('div',[_vm._v(_vm._s(a.area.name))]):_vm._e()])}),1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-delete"); }) || _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id, index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])]):_vm._e(),(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-update"); }) || _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" fi fi-rr-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])]):_vm._e(),(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-changePassword"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openChangePasswordDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" fi fi-rr-key ")])],1)]}}],null,true)},[_c('span',[_vm._v("تغيير كلمة المرور")])]):_vm._e(),(_vm.$route.path == '/delivery_delegater' || _vm.$route.path == '/receiving_delegater')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.assignAreas(item)}}},on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v(" fi fi-rr-route ")])],1)]}}],null,true)},[_c('span',[_vm._v("تحديد مناطق المندوب")])]):_vm._e()]}}])})],1),_c('Pagination'),_c('Dialog'),_c('ChangePasswordDialog'),_c('AssignAreasDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }